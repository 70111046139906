<template>
  <el-dialog
    title="生成入库单"
    width="1600px"
    :visible.sync="selfShow"
    :close-on-click-modal="false"
  >
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfDetail.formData"
      />
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :edit-rules="validRules"
        :columns="tableColumn"
        :data="selfDetail.list"
        :show-footer="!!selfDetail.list.length"
        :max-height="this.$util.getViewHeight() - 360"
      >
        <!-- slot_default -->
        <template #default_create_date="{ row }">
          {{ row.create_date ? row.create_date.slice(0, 10) : '' }}
        </template>

        <!-- slot_edit -->
        <template #edit_ru_ku_count="{ row }">
          <el-input
            v-model="row.ru_ku_count"
            type="number"
            size="mini"
            clearable
            @blur="amountUpdate(row)"
          />
        </template>
        <template #edit_ru_ku_price="{ row }">
          <el-input
            v-model="row.ru_ku_price"
            type="number"
            size="mini"
            clearable
            @blur="amountUpdate(row)"
          />
        </template>
        <template #edit_cang_ku="{ row }">
          <el-select
            style="width: 100%"
            placeholder="请选择"
            size="mini"
            v-model="row.cang_ku"
            clearable
          >
            <el-option
              v-for="(item, index) in warehouseList"
              :key="index"
              :label="item.key"
              :value="item.val"
            />
          </el-select>
        </template>
      </vxe-grid>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        formData: {
          song_huo_dan_bill_photo_list: [], //送货单图片
          ru_ku_remark: '' // 入库备注
        },
        list: []
      },
      formConfig: {
        inline: true,
        width: '240px'
      },
      formColumn: [
        {
          type: 'upload',
          title: '送货单图片',
          field: 'song_huo_dan_bill_photo_list',
          size: '28px',
          isDelete: true
        },
        {
          type: 'text',
          title: '入库备注',
          field: 'ru_ku_remark'
        }
      ],
      tableColumn: [
        {
          fixed: 'left',
          type: 'checkbox',
          width: 40
        },
        {
          title: '序号',
          type: 'seq',
          width: 50
        },
        {
          title: '单据日期',
          field: 'create_date',
          width: 80,
          slots: { default: 'default_create_date' }
        },
        {
          title: '来源单号',
          field: 'ru_ku_code',
          width: 104
        },
        {
          title: '入库类型',
          field: 'ru_ku_type',
          width: 80
        },
        {
          title: '供应商',
          field: 'supplier_company_name'
        },
        {
          title: '业务员',
          field: 'saler_name'
        },
        {
          title: '品名',
          field: 'craft'
        },
        {
          title: '工序',
          field: 'process_order_name'
        },
        {
          title: '工艺',
          field: 'process_name'
        },
        {
          title: '材质',
          field: 'texture'
        },
        {
          title: '规格',
          field: 'specification',
          width: 120
        },
        {
          title: '单位',
          field: 'unit'
        },
        {
          title: '申请入库数量',
          field: 'shen_qing_ru_ku_count',
          width: 90
        },
        {
          title: '入库数量',
          field: 'ru_ku_count',
          width: 90,
          slots: { edit: 'edit_ru_ku_count' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '入库单价(元)',
          field: 'ru_ku_price',
          width: 110,
          slots: { edit: 'edit_ru_ku_price' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '入库金额(元)',
          width: 90,
          field: 'ru_ku_amount'
        },
        {
          title: '仓库',
          field: 'cang_ku',
          slots: { edit: 'edit_cang_ku' },
          editRender: { autofocus: '.el-input__inner' }
        }
      ],
      validRules: {
        ru_ku_count: [{ required: true }],
        ru_ku_price: [{ required: true }],
        cang_ku: [{ required: true }]
      },
      warehouseList: []
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let disabled = this.disabled
      let obj = {
        editConfig: {
          beforeEditMethod() {
            return !disabled
          }
        },
        keyboardConfig: {
          isEdit: !disabled
        }
      }
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getDetailData()
    this.getWarehouseList()
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      let ids = this.list.map((item) => item.id).join(',')
      this.$api({
        method: 'get',
        url: '/admin/gcvip/rukushenqing/buildRuKuBill',
        params: {
          ids: ids
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          let list = this.$util.deepCopy(res.data.list)
          list.forEach((item) => {
            // 初始化数量、金额、选中状态
            item.ru_ku_count = item.shen_qing_ru_ku_count
            item.ru_ku_amount = this.$XEUtils.multiply(item.ru_ku_count, item.ru_ku_price) || ''
          })
          this.selfDetail.list = this.$util.deepCopy(list)
          this.selfDetail.list.forEach((item) => {
            this.$nextTick(() => {
              this.$refs.refTable.setCheckboxRow([item], true) // 默认选中
            })
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取-仓库列表
    getWarehouseList() {
      this.$api({
        method: 'post',
        url: '/admin/warehouseSetting',
        data: {
          currentPage: 1,
          pageSize: this.$constant.pageMax
        }
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          let result = res.data.page.list.map((item) => {
            return {
              key: item.name,
              val: item.name
            }
          })
          this.warehouseList = result
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 金额更新
    amountUpdate(row) {
      row.ru_ku_amount = this.$XEUtils.multiply(row.ru_ku_count, row.ru_ku_price) || ''
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 获取-当前复选行
    getCheckboxRecords() {
      return this.$refs.refTable.getCheckboxRecords()
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      let rows = this.getCheckboxRecords()
      if (rows.length) {
        let isPassCount = rows.every((item) => !!Number(item.ru_ku_count))
        let isPassPrice = rows.every((item) => !!Number(item.ru_ku_price))
        let isPassCangKu = rows.every((item) => item.cang_ku)
        if (!isPassCount) {
          this.$message.error('选中行的入库数量必须>0')
          return
        }
        if (!isPassPrice) {
          this.$message.error('选中行的入库单价必须>0')
          return
        }
        if (!isPassCangKu) {
          this.$message.error('选中行的仓库不能为空')
          return
        }
      } else {
        this.itemChoosePrompt()
      }
      let params = {
        formData: {
          ...this.selfDetail.formData
        },
        list: [...rows]
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/ruku/saveOrUpdate',
        data: params
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.popupCancel()
            this.$emit('success')
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    }
  }
}
</script>

<style lang="less" scoped></style>
